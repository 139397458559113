<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/InverterBroken/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">編號</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="inverter_tb_id" class="col-sm-2 col-form-label">逆變器流水號</label>
							<div class="col-sm-10">
								<input v-model="model.inverter_tb_id"
									type="text"
									class="form-control"
									id="inverter_tb_id"
									name="inverter_tb_id"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="brand" class="col-sm-2 col-form-label">逆變器廠牌</label>
							<div class="col-sm-10">
								<input v-model="model.brand"
									type="text"
									class="form-control"
									id="brand"
									name="brand"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="name" class="col-sm-2 col-form-label">逆變器型號</label>
							<div class="col-sm-10">
								<input v-model="model.name"
									type="text"
									class="form-control"
									id="name"
									name="name"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="sn" class="col-sm-2 col-form-label">逆變器序號</label>
							<div class="col-sm-10">
								<input v-model="model.sn"
									type="text"
									class="form-control"
									id="sn"
									name="sn"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="inverter_id" class="col-sm-2 col-form-label">逆變器串接位址</label>
							<div class="col-sm-10">
								<input v-model="model.inverter_id"
									type="text"
									class="form-control"
									id="inverter_id"
									name="inverter_id"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="capacity" class="col-sm-2 col-form-label">面板組KW數</label>
							<div class="col-sm-10">
								<input v-model="model.capacity"
									type="text"
									class="form-control"
									id="capacity"
									name="capacity"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dt1" class="col-sm-2 col-form-label">啟用日期</label>
							<div class="col-sm-10">
								<input v-model="model.dt1"
									type="date"
									class="form-control"
									id="dt1"
									name="dt1"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="edit_date" class="col-sm-2 col-form-label">修改時間</label>
							<div class="col-sm-10">
								<input v-model="model.edit_date"
									type="date"
									class="form-control"
									id="edit_date"
									name="edit_date"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="disuse_dt1" class="col-sm-2 col-form-label">汰換日期</label>
							<div class="col-sm-10">
								<input v-model="model.disuse_dt1"
									type="date"
									class="form-control"
									id="disuse_dt1"
									name="disuse_dt1"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="pw_meter1" class="col-sm-2 col-form-label">年初累計發電度數</label>
							<div class="col-sm-10">
								<input v-model="model.pw_meter1"
									type="text"
									class="form-control"
									id="pw_meter1"
									name="pw_meter1"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="pw_meter2" class="col-sm-2 col-form-label">故障時最後發電度數</label>
							<div class="col-sm-10">
								<input v-model="model.pw_meter2"
									type="text"
									class="form-control"
									id="pw_meter2"
									name="pw_meter2"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="pw_year_cal" class="col-sm-2 col-form-label">年發電度數結算</label>
							<div class="col-sm-10">
								<input v-model="model.pw_year_cal"
									type="text"
									class="form-control"
									id="pw_year_cal"
									name="pw_year_cal"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="broken_dt" class="col-sm-2 col-form-label">最後發電日期</label>
							<div class="col-sm-10">
								<input v-model="model.broken_dt"
									type="date"
									class="form-control"
									id="broken_dt"
									name="broken_dt"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note"
									type="text"
									class="form-control"
									id="note"
									name="note"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getInverterBroken, postInverterBroken } from '@/api/InverterBroken.js'

	export default {
		name: 'InverterBrokenEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					id: '',
					inverter_tb_id: '',
					housecode: '',
					brand: '',
					name: '',
					sn: '',
					inverter_id: '',
					capacity: '',
					dt1: '',
					edit_date: '',
					disuse_dt1: '',
					pw_meter1: '',
					pw_meter2: '',
					pw_year_cal: '',
					broken_dt: '',
					note: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getInverterBroken(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postInverterBroken(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>